import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-wrapper',
  templateUrl: './gallery-wrapper.component.html',
  styleUrls: ['./gallery-wrapper.component.css']
})
export class GalleryWrapperComponent implements OnInit {
  imageInformationHor = [
    ["Garten-min.JPG", "Garten", "horizontal"],
    ["hof_mit_strandkorb-min.jpeg", 'Außenbereich: Strandkorb und Stellplatz für PKW auf abgeschlossenem Grundstück', "horizontal"],
    ["Kueche2-min.JPG", "Küche", "horizontal"],
    ["Kueche3-min.JPG", "Küche", "horizontal"],
    ["Kueche-min.JPG", "Küche", "horizontal"],
    ["Schlafzimmer-min.JPG", "Schlafzimmer", "horizontal"],
    ["Schlafzimmer2-min.JPG", "Schlafzimmer", "horizontal"],
    ["Wohnzimmer-min.JPG", "Wohnzimmer", "horizontal"],
    ["Wohnzimmer2-min.JPG", "Wohnzimmer", "horizontal"],
    ["Bad-min.JPG", "Bad", "horizontal"]
  ];

  imageInformationVer = [
    ["Dusche1-min.JPG", "Dusche", "vertical"],
    ["Dusche2-min.JPG", "Dusche", "vertical"]
  ];

  constructor() { }

  ngOnInit() {
  }

}
