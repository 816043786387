import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryImageComponent } from './gallery-image/gallery-image.component';
import { GalleryWrapperComponent } from './gallery-wrapper/gallery-wrapper.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DataprotectionComponent } from './dataprotection/dataprotection.component';
import { MainComponent } from './main/main.component';
import { AgmCoreModule } from '@agm/core';

const appRoutes: Routes = [
  { path: '', component: MainComponent },
  { path: 'impressum',      component: ImpressumComponent },
  { path: 'datenschutz',      component: DataprotectionComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    GalleryImageComponent,
    GalleryWrapperComponent,
    ImpressumComponent,
    DataprotectionComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes
    ),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDuJAlB4LotCBod24fzLiiHuB9TTOh3p0Q'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
